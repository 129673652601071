import React from 'react';
import heroMilana from "../../image/milana-hero.jpg"
const Hero = () => {
    return (
        <div id="hero">
            <div className="container">
                <div className="hero">
                    <div className="hero--title">
                        <img className="hero--title__img" src={heroMilana} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;