import React from 'react';
import './App.scss';
import Home from "./components/Home";
import Header from "./components/Header";
import Footer from "./components/Footer/footer";
import Hero from "./pages/Hero";
import {Route, Routes} from "react-router-dom";
import Biography from "./pages/Biography";


function App() {
  return (
    <div className="App">
        <Header/>
        <Routes>
            <Route path="/biography" element={<Biography/>}/>
            <Route path="/" element={<Home/>}/>
        </Routes>
        <Footer/>
    </div>
  );
}

export default App;