import React from 'react';
import {NavLink} from "react-router-dom";
import {BsInstagram, BsTelegram, BsWhatsapp} from "react-icons/bs";

const Footer = () => {
    return (

        <div id="footer">
            <div className="container">
                <div className="footer">
                   <div className="footer--title">
                       <NavLink className="footer--title__bio" to={"/biography"}>
                           My biography
                       </NavLink>
                       <NavLink className="footer--title__whatsapp" to={"https://wa.me/+996220340337"}>
                           <BsWhatsapp/>
                       </NavLink>
                       <NavLink className="footer--title__telegram" to={"https://t.me/thoughtss21"}>
                           <BsTelegram/>
                       </NavLink>
                       <NavLink className="footer--title__instagram" to={"https://www.instagram.com/21miilan/"}>
                           <BsInstagram/>
                       </NavLink>

                   </div>

                </div>
            </div>
        </div>
    );
};

export default Footer;