import React from 'react';
import Hero from "../../pages/Hero";

const Home = () => {
    return (
        <div>
                    <Hero/>
        </div>
    );
};

export default Home;