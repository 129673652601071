import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {FaInstagram, FaTelegram, FaTiktok} from "react-icons/fa";
import {BsInstagram, BsTelegram, BsTiktok} from "react-icons/bs";
import tiktok from "../../image/tilto.png"
const Header = () => {
    return (
        <div id="header">
            <div className="container">
                <div className="header">
                    <NavLink className="header--bio" to={"/biography"}>
                       My biography
                   </NavLink>
                    <NavLink className="header--bio" to={"/"}>
                       Main
                   </NavLink>
                   <div className="header--link">
                       <NavLink className="header--link__site" to={""}>
                           Me at webSites
                       </NavLink> <br/><br/>
                       <Link className="header--link__instagram" to={"https://www.instagram.com/21miilan/"}>
                           <BsInstagram/>
                       </Link>
                       <Link className="header--link__tiktok" to={"https://www.tiktok.com/@21endlesslovee"}>
                           <img src={tiktok} alt=""/>
                       </Link>
                       <Link className="header--link__telegram" to={"https://t.me/thoughtss21"}>
                           <BsTelegram/>
                       </Link>
                   </div>
                </div>
            </div>
        </div>
    );
};

export default Header;