import React from 'react';
import first  from "../../image/bio-first.jpg"
import second  from "../../image/bio-second.jpg"
import third  from "../../image/bio-third.jpg"
import fourth  from "../../image/bio-fourth.jpg"
import last  from "../../image/bio-five.jpg"
import six  from "../../image/six.jpg"
import seven  from "../../image/seven.jpg"
import eight  from "../../image/eight.jpg"
const Biography = () => {
    return (
        <div>
           <div id="biography">
               <div className="container">
                 <div className="biography">

                     <img src={second} alt=""/>

                 </div>
               </div>
           </div>
            <div id="bioSecond">
                    <div className="bioSecond">
                        <div className="bioSecond--img">
                            <img src={first} alt=""/>
                            <img src={third} alt=""/>
                            <img src={fourth} alt=""/>
                            <img src={last} alt=""/>
                            <img src={six} alt=""/>
                            <img src={seven} alt=""/>
                            <img src={eight} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Biography;